// extracted by mini-css-extract-plugin
export var automobile = "index-module--automobile--MMalh";
export var clickArea_auto = "index-module--clickArea_auto--FzHgv";
export var content = "index-module--content--K652w";
export var contentHover = "index-module--contentHover--ysEWb";
export var descBg = "index-module--descBg--PWSD1";
export var descContent = "index-module--descContent--wJtky";
export var experience = "index-module--experience--Prss5";
export var experienceKPI = "index-module--experienceKPI--bs1+8";
export var finger_matting = "index-module--finger_matting--FC88l";
export var guidance = "index-module--guidance--fhkEn";
export var hoverShow = "index-module--hoverShow--v66+x";
export var hoverUp = "index-module--hover-up--rzUEL";
export var position_left = "index-module--position_left--UvsoM";
export var viewData = "index-module--viewData--JV0WQ";
export var watchVideo = "index-module--watchVideo---2qq1";